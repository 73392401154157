
export default{
    isLogin:function(_this){
        if(!_this.$store.getters.getLoginValue){
            return _this.$router.push("Login")
        }
    },
	qi2qi(str){
		if(!str) return "";
		return str.splice(4)
	}
}
