<template>
  <div id="app">
      <router-view :key="newkey"></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0,
		newkey:123
    };
  },
  mounted(){
    //为pc则用iframe把移动端页面显示到页面中间
    let userAgentInfo = window.navigator.userAgent
    let Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod')
    if (!Agents.some(item => userAgentInfo.toLowerCase().includes(item.toLowerCase())) && !(self.frameElement && self.frameElement.tagName == 'IFRAME')) {
      let ifrTag = document.getElementsByTagName('iframe')[0]
      if (ifrTag) {
        // 如果有，则删除它
        ifrTag.remove()
      } else {
        let ifrTag = document.createElement('iframe')
        document.body.innerHTML = ''
        ifrTag.setAttribute('src', window.location.href)
        let styleObj = {
          width: '480px',
          height: '100vh',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }
        Object.entries(styleObj).forEach(([key, value]) => {
          ifrTag.style[key] = value
        })
        document.body.append(ifrTag)
      }
    }
  },
  methods: {
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{

        if(!localStorage.getItem('token') && this.$route.fullPath!='/Home'){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created(){
	this.key=Math.ceil(Math.random()*99999999999);
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*, :after, :before {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
